import React from "react"
import ContactModal from "./contact-modal"

export default () => {
    const [modalOpen, setModalOpen] = React.useState(false)   
    const toggle = () => setModalOpen(!modalOpen)   

    return [toggle, 
        () => <ContactModal
            isActive={modalOpen}
            handleClose={() => setModalOpen(false)}
        />] 
}