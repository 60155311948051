/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import "../styles/styles.scss"
import Header from "./header"
import useModal from "../components/use-modal"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

const Layout = ({ children }) => {
  const location = useLocation()
  const { t } = useTranslation([
    "page_seminaries",
    "components",
    "cities",
    "keywords",
    "slugs",
  ])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const tSeminaries = t("products")

  const tCities = t("cities:cities")

  const tKeywords = t("keywords:keywords")
  const kw = Object.values(tKeywords)

  const tFooter = t("components:footer")

  const tSlugs = t("slugs:slugs")

  const tCookieBanner = t("components:cookie_banner")

  const [toggle, ContactModal] = useModal()

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} toggle={toggle} />
      <main>{children}</main>
      <footer className="footer has-text-centered-mobile">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-4-desktop is-6-tablet mb-5">
              <p className="mb-2">
                <b>Sem'On Business</b>
              </p>
              <p className="is-size-6">
                Terlinden, 105 <br />
                B-1785 Merchtem <br />
                <br />
                TVA BE 0869.619.450 <br />
                IBAN BE20 0016 0797 4656 <br />
                <br />
                <span className="is-size-5">
                  <a href="tel::03252462725">+32 (0) 52 46 27 25</a>
                  <br />
                </span>
                <span className="is-size-5">
                  <a href="mailto:info@semonbusiness.com">
                    info@semonbusiness.com
                  </a>
                </span>
              </p>
            </div>
            <div className="column is-3-desktop is-6-tablet mb-5">
              <h3 className="title is-4 mb-3 has-text-weight-bold has-text-grey">
                {tFooter.teambuilding}
              </h3>

              <ul className="is-size-5">
                {Object.values(tCities).map((city, index) => (
                  <li key={city}>
                    {/* TODO : Handle slugs */}
                    <Link to={tSlugs["/teambuildings/"]}>
                      {kw[Object.keys(kw)[index % Object.keys(kw).length]] +
                        " "}
                      <span className="has-text-grey-dark">
                        {tFooter.teambuilding_in_city + " "}
                        {city}
                      </span>
                    </Link>
                  </li>
                ))}
                <li>{tFooter.teambuilding_activity_more}</li>
              </ul>
            </div>
            <div className="column is-3-desktop is-6-tablet mb-5">
              <h3 className="title is-4 mb-3 has-text-weight-bold has-text-grey">
                {tFooter.seminaries}
              </h3>
              <ul className="is-size-5">
                {tSeminaries.products.map((product, index) => (
                  <li key={index}>
                    <Link to={tSlugs["/seminaires-et-congres/"]}>
                      <span className="has-text-grey-dark">
                        {product.title.split(" ")[0]}{" "}
                      </span>
                      {product.title.split(" ").slice(1).join(" ")}
                    </Link>
                    <br />
                    <br />
                  </li>
                ))}
              </ul>
            </div>
            <div className="column is-2-desktop is-6-tablet mb-5">
              <h3 className="title is-4 mb-3 has-text-weight-bold has-text-grey">
                {tFooter.contact_us}
              </h3>
              <p className="is-size-5 mb-3">
                <a
                  className="button is-primary is-rounded"
                  href="mailto:info@semonbusiness.com"
                >
                  <b>info@semonbusiness.com</b>
                </a>
              </p>

              <p className="is-size-5 mb-3">
                <a
                  className="button is-primary is-rounded"
                  href="tel::03252462725"
                >
                  <b>+32 (0) 52 46 27 25</b>
                </a>
              </p>
              <p className="is-size-5 mb-3">
                <Link
                  className="button is-primary is-rounded"
                  to='/contact'
                >
                  <b>{tFooter.contact_form}</b>
                </Link>
              </p>
            </div>
          </div>
          <p className="is-size-7">
            © {new Date().getFullYear()}, built by
            {` `}
            <a href="https://codefathers.be">codefathers</a>
          </p>
        </div>
      </footer>
      <ContactModal />
      <CookieConsent
        location="bottom"
        buttonText={`✔ ${tCookieBanner.accept_button}`}
        enableDeclineButton
        declineButtonText={`✘  ${tCookieBanner.decline_button}`}
        cookieName="gatsby-gdpr-google" // Enables both google analytics and tag manager
        disableButtonStyles={true}
        buttonClasses="button is-small is-outlined is-success has-text-weight-bold mx-3 my-2"
        declineButtonClasses="button is-small is-outlined is-danger has-text-weight-bold mx-3 my-2"
        buttonWrapperClasses="is-flex is-flex-direction-column-reverse"
        containerClasses="is-align-items-center is-justify-content-center"
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        <p className="title is-5">{tCookieBanner.title}</p>
        <p className="is-size-6">
          {tCookieBanner.content}
          <br />
          <Link
            to={tSlugs["/privacy-policy/"]}
            className="has-text-white"
            style={{ textDecoration: "underline" }}
          >
            {tCookieBanner.link_privacy_policy}
          </Link>
        </p>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
