import PropTypes from "prop-types"
import React from "react"
import {
  useTranslation,
  useI18next,
  Link,
  I18nextContext,
} from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"

const Header = ({ toggle }) => {
  const allSlugsFromGraphql = useStaticQuery(
    graphql`
      query MyQuery {
        allLocale(filter: { ns: { eq: "slugs" } }) {
          edges {
            node {
              language
              data
              ns
            }
          }
        }
      }
    `
  )

  const allSlugs = {}
  allSlugsFromGraphql.allLocale.edges.forEach(
    ({ node: { data, language, ns } }) =>
      (allSlugs[language] = JSON.parse(data)["slugs"])
  )

  const [showMenu, setShowMenu] = React.useState(false)

  const { t, i18n } = useTranslation("components", "slugs")

  const { language: currentLanguage, languages, originalPath } = useI18next()
  let pagePath = originalPath
  const otherLanguages = languages.filter(
    language => language !== currentLanguage
  )
  if (originalPath.startsWith("/news/")) {
    // We are on a single news page, and there's no translation provided
    // Because news translations are not linked / relational
    // we route to the News homepage in the other language instead
    pagePath = "/news"
  }


  const { nav_menu: tMenu } = t("header")
  const tSlugs = t("slugs:slugs")

  return (
    <header>
      <nav
        className="navbar is-spaced is-size-5-desktop is-size-4-widescreen"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <b>Sem'On Business</b>
          </Link>
          <span
            role="button"
            aria-label="menu"
            aria-expanded="false"
            data-target="navigationLinks"
            className={[
              "navbar-burger burger",
              showMenu ? "is-active" : undefined,
            ].join(" ")}
            onClick={() => setShowMenu(!showMenu)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>

        <div
          id="navigationLinks"
          className={["navbar-menu", showMenu ? "is-active" : undefined].join(
            " "
          )}
        >
          <div className="navbar-start"></div>

          <div className="navbar-end">
            <Link
              to={tSlugs["/teambuildings/"]}
              className="navbar-item"
              activeClassName="is-active"
            >
              {tMenu.teambuilding}
            </Link>
            <Link
              to={tSlugs["/seminaires-et-congres/"]}
              className="navbar-item"
              activeClassName="is-active"
            >
              {tMenu.seminaries}
            </Link>
            <Link
              to={tSlugs["/a-propos-de-semonbusiness/"]}
              className="navbar-item"
              activeClassName="is-active"
            >
              {tMenu.about}
            </Link>
            <Link
              to={tSlugs["/references-clients/"]}
              className="navbar-item"
              activeClassName="is-active"
            >
              {tMenu.references}
            </Link>
            <Link
              to={"/news"}
              className="navbar-item"
              activeClassName="is-active"
            >
              News
            </Link>
            <div className="navbar-item">
              <button onClick={toggle} className="button is-dark is-rounded">
                <b>+32 (0) 52 46 27 25</b>
              </button>
            </div>

            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link is-arrowless has-text-weight-bold">
                &nbsp;{currentLanguage}&nbsp;
              </a>
              {otherLanguages[0] && (
                <div className="navbar-dropdown is-boxed is-right">
                  {otherLanguages.map(lang => (
                    <Link
                      key={lang}
                      className="navbar-item"
                      to={
                        allSlugs[lang][pagePath]
                          ? allSlugs[lang][pagePath]
                          : pagePath
                      }
                      language={lang}
                      style={{ paddingRight: "1rem" }}
                    >
                      {lang}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
