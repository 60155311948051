import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "placeholder-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pictos: allFile(filter: {relativeDirectory: {eq: "pictos"}}) {
        nodes {
          name
          childImageSharp {
            fluid(
              maxWidth: 150,
              srcSetBreakpoints: [150],
              duotone: {
                highlight: "#000000",
                shadow: "#ffffff"
              }) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      ccm: allFile(filter: {relativeDirectory: {eq: "ccm"}}) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      pictures: allFile(filter: {relativeDirectory: {eq: "pictures"}}) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1280, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      logos: allFile(filter: {relativeDirectory: {eq: "logos"}}) {
        nodes {
          name
          childImageSharp {
            fluid(
              maxWidth: 150,
              srcSetBreakpoints: [150],
              ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
  }
  `)

  const placeholderImage = () => <Img fluid={data.placeholderImage.childImageSharp.fluid} />
  const pictos = ({ name, ...rest }) => {
    const pictoIndex = data.pictos.nodes.findIndex(node => node.name === name)
    return (
      pictoIndex > -1
        ?
        <>
          <Img fluid={data.pictos.nodes[pictoIndex].childImageSharp.fluid} {...rest} />
          {/* <div className="px-1 py-1 is-absolute is-size-7 has-text-weight-bold" style={{ backgroundColor: 'green', color: 'white' }}>{rest.alt && rest.alt}</div> */}
        </>
        : placeholderImage()
    )
  }
  const ccm = ({ name, ...rest }) => {
    const ccmIndex = data.ccm.nodes.findIndex(node => node.name === name)
    return (
      ccmIndex > -1
        ? <Img fluid={data.ccm.nodes[ccmIndex].childImageSharp.fluid} {...rest} />
        : placeholderImage()
    )
  }
  const pictures = ({ name, ...rest }) => {
    const pictureIndex = data.pictures.nodes.findIndex(node => node.name === name)
    return (
      pictureIndex > -1
        ? <>
          <Img fluid={data.pictures.nodes[pictureIndex].childImageSharp.fluid} {...rest} />
          {/* <div className="px-1 py-1 is-absolute is-size-7 has-text-weight-bold" style={{ backgroundColor: 'green', color: 'white' }}>{rest.alt && rest.alt}</div> */}
        </>
        : placeholderImage()
    )
  }
  const logos = ({ name, ...rest }) => {
    const logosIndex = data.logos.nodes.findIndex(node => node.name === name)
    return (
      logosIndex > -1
        ? <Img fluid={data.logos.nodes[logosIndex].childImageSharp.fluid} {...rest} />
        : placeholderImage()
    )
  }
  const allLogos = data.logos.nodes

  return {
    PlaceholderImage: placeholderImage,
    Picto: pictos,
    Picture: pictures,
    Logo: logos,
    CCM: ccm,
    allLogos: allLogos,
  }

}