import React from "react"
import { Link } from "gatsby-plugin-react-i18next"

export default ({ to, children, small, className, onClick, ...rest }) => {
    const AnchorType = onClick ? "button" : Link
    return (
        <AnchorType
            onClick={onClick}
            to={to}
            className={
                [
                    "button is-medium-mobile is-primary is-uppercase has-text-weight-bold is-rounded",
                    small ? "" : "is-large",
                    className
                ]
                    .join(" ")}
            {...rest}
        >
            {children}
        </AnchorType>
    )
}