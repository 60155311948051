import React, { useState } from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

export default ({ isActive, handleClose }) => {
  const { t } = useTranslation("components")
  const tModal = t("contact_modal")

  return (
    <div className={["modal", isActive && "is-active"].join(" ")}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content section has-background-dark has-text-light">
        <p className="title is-2 is-size-3-mobile has-text-primary">
          {tModal.title}
        </p>
        <p className="subtitle is-4 is-size-5-mobile has-text-light">
          {tModal.subtitle}
        </p>
        <>
          <p className="mb-3">
            {tModal.call_us_at}{" "}
            <a href="tel::03252462725">
              <b>+32 (0) 52 46 27 25</b>
            </a>
          </p>
          <p className="mb-3">
            {tModal.email_us_at}{" "}
            <a href="mailto:info@semonbusiness.com">
              <b>info@semonbusiness.com</b>
            </a>
          </p>
          <p className="mb-3">
            {tModal.send_form}{" "}
            <Link to='/contact'>
              <b>{tModal.send_form_cta}</b>
            </Link>
          </p>
        </>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={handleClose}
      ></button>
    </div>
  )
}
